import { gql } from '@apollo/client'

export const USER_CHANGES_FRAGMENT = gql`
  fragment UserChangesFields on UserInfoChanges {
    firstname
    lastname
    lastnameMaternal
    phoneNumber
    email
    profilePicture {
      id
      url
      name
    }
    description
  }
`

export const USER_FRAGMENT = gql`
  fragment CoreUserFields on User {
    id
    isAdmin

    firstname
    lastname
    lastnameMaternal
    rfc
    constanciaFiscal {
      id
      url
      name
    }
    regimenFiscal
    civilStatus
    usoCFDI
    birthdate
    address {
      street1
      street2
      neighborhood
      colonia
      city
      state
      zipcode
      country
    }
    email
    phoneNumber
    description
    languages
    facebookUrl
    instagramUrl
    twitterUrl
    interest
    customerType
    hostType
    job
    currentCity
    hobbie
    movieAsLife
    favoriteSong

    identification {
      id
      url
      name
    }
    profilePicture {
      id
      url
      name
    }

    bussinessProfile {
      name
      description
      profilePicture {
        id
        url
        name
        width
        height
      }
    }

    isVerified
    accountVerificationError
    requestedVerification

    createdOn
    lastUpdatedOn
    lastVerifiedOn

    rfc
  }
`

export const CARD_LOCATION_FIELDS = gql`
  fragment CardLocationFields on Location {
    id
    urlRoute
    coordinates {
      lat
      lng
    }
    shortAddress
    addressDetails {
      street1
      street2
      zipcode
      neighborhood
      colonia
      city
      state
      country
    }
    size {
      width
      length
    }
    description
    locationType
    price
    photos {
      url
    }
    coverPhoto {
      url
    }
    propertyType
    bussinessProfile {
      name
      description
      profilePicture {
        id
        url
        name
        width
        height
      }
    }
    user {
      id
      firstname
      lastname
      profilePicture {
        url
      }
    }
  }
`

export const ALL_LOCATION_FIELDS = gql`
  ${USER_FRAGMENT}

  fragment AllLocationFields on Location {
    id
    urlRoute
    quantity
    availability

    address
    shortAddress
    addressDetails {
      street1
      street2
      neighborhood
      colonia
      city
      state
      zipcode
      country
    }
    coordinates {
      lat
      lng
    }
    publicAddress

    title
    description
    size {
      width
      length
      height
    }
    privateSpace
    canStoreVehicle
    indoorOrOutdoor
    locationType
    price
    dynamicPricing
    minPrice
    photos {
      id
      url
      name
      width
      height
    }
    coverPhoto {
      id
      url
      name
      width
      height
    }
    welcomeMessage
    accessPermission
    accessFrequency
    accessHours
    accessAvailability
    features
    agreedToTerms
    useOfSpace
    propertyType
    discountEnabled

    submittedBy
    submittedOn
    lastUpdatedOn

    isDeactivated
    isDraft
    isVerified
    reasonForDenial

    requestedVerification
    isVerified
    reasonForDenial

    user {
      ...CoreUserFields
    }
    bussinessProfile {
      name
      description
      profilePicture {
        id
        url
        name
        width
        height
      }
    }

    contract {
      id
      url
      name
      width
      height
    }
  }
`

export const CHECKOUT_FRAGMENT = gql`
  ${USER_FRAGMENT}
  ${ALL_LOCATION_FIELDS}

  fragment CoreCheckoutFields on Checkout {
    id
    locationId
    renterId
    landlordId
    price
    currency
    quantity
    pricing {
      impuestos
      insurance
      tde
      tdera
      tdp
      tds
      tf
    }
    storageTime
    storageItems
    storageNeeds
    storageItemsPhotos {
      id
      url
      name
    }
    insuranceType
    vehicles {
      color
      make
      model
      type
      year
    }

    stripeSessionCreatedAt
    stripeSessionId
    stripePaymentMethodId
    stripeCheckoutSessionUrl
    stripeSubscriptionId
    stripePaymentMethodCompletedAt

    isLandlordApproved
    landlordNotes
    rejectionReason
    endedReason

    requestedStartDate
    createdAt
    completedAt
    startedAt
    landlordAssessedAt
    cancelledAt
    endedAt
    hasEnded

    renter {
      ...CoreUserFields
    }
    landlord {
      ...CoreUserFields
    }
    location {
      ...AllLocationFields
    }
  }
`

export const MESSAGE_FRAGMENT = gql`
  fragment AllMessageFields on Message {
    id
    authorId
    chatId
    timestamp
    readReceipts {
      memberId
      read
    }
    message
    files {
      id
      url
      name
    }
    event {
      eventType
      date
    }
    author {
      id
      profilePicture {
        url
      }
    }
    checkout {
      id
      locationId
      price
      storageNeeds
      location {
        id
        urlRoute
        photos {
          url
        }
        coverPhoto {
          url
        }
        locationType
        shortAddress
        size {
          width
          length
          height
        }
        price
        minPrice
      }
    }
    locations {
      id
      urlRoute
      coverPhoto {
        url
      }
      photos {
        url
      }
      locationType
      shortAddress
      size {
        width
        length
        height
      }
      price
      minPrice
    }
    reported
  }
`

export const ALL_CHAT_FIELDS = gql`
  ${USER_FRAGMENT}
  ${MESSAGE_FRAGMENT}

  fragment AllChatFields on Chat {
    id
    memberIds
    lastMessage {
      message
      files {
        url
      }
    }
    lastMessageTimestamp
    members {
      ...CoreUserFields
    }
    unreadMessages
    messages(pagination: $pagination) {
      total
      messages {
        ...AllMessageFields
      }
    }
  }
`

export const CARD_CHAT_FIELDS = gql`
  ${USER_FRAGMENT}

  fragment CardChatFields on Chat {
    id
    memberIds
    members {
      ...CoreUserFields
    }
    lastMessage {
      message
      files {
        url
      }
    }
    lastMessageTimestamp
    unreadMessages
  }
`

export const ALL_REVIEW_FIELDS = gql`
  ${USER_FRAGMENT}

  fragment AllReviewFields on Review {
    interest
    rating
    review
    createdAt

    response
    respondedAt

    reviewerId
    reviewer {
      ...CoreUserFields
    }
  }
`
